import { Link } from 'gatsby-theme-material-ui';
import { Typography } from '@mui/material';

import MainLayout from '../components/MainLayout';
import Block from '../components/Block';
import SmallHero from '../components/SmallHero';
import backgroundImage from '../images/susan-q-yin-Ctaj_HCqW84-unsplash.jpg';

export function Head() {
  return <title>Not found - Veikko Mäkinen</title>;
}

function NotFoundPage() {
  return (
    <MainLayout>
      <SmallHero title="Oh no.. " backgroundImage={backgroundImage} overlayColor="#000" overlayOpacity={0.8} />
      <Block>
        <Typography variant="h2" gutterBottom>
          Page not found
        </Typography>
        <Typography variant="body1" gutterBottom>
          I am sorry but you seem to have found a dead end. I am not sure what happened :(
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please navigate back
          {' '}
          <Link to="/">home</Link>
          {' '}
          or select another page from the menu.
        </Typography>
      </Block>
    </MainLayout>
  );
}

export default NotFoundPage;

// export const Head = () => <title>Not found</title>
